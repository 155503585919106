import React from 'react';
import Layout from '../components/Layout';
import Text from '../components/shared/Text';
import Section from '../components/shared/Section';
import Button from '../components/swace/button';
import * as s from './404.module.scss';
import globeSvg from '../img/globe.svg';

const NotFoundPage = () => (
  <Layout disableBackground={true}>
    <div className={s.fullHeightPage}>
      <Section>
        <div className={`row d-flex align-items-center w-100`}>
          <div className="col-sm-8 d-flex flex-column order-2 order-sm-1">
            <Text h1>404</Text>
            <div className={s.ingress}>
              <Text ingressLarge>Page not found.</Text>
            </div>
            <div className={s.bread}>
              <Text ingress>Error: We could not find the requested page.</Text>
            </div>
            <div className={s.link}>
              <Button dark slim href="/">
                Go back to Zoundindustries.com
              </Button>
            </div>
          </div>
          <div className={['col-sm-4 order-1 order-sm-2', s.image].join(' ')}>
            <img src={globeSvg} />
          </div>
        </div>
      </Section>
    </div>
  </Layout>
);

export default NotFoundPage;
